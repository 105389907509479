const currentUrl = 'http://zerocool.meloncreative.co.uk/preview/woodwardsurveys2018';

jQuery(document).ready(function ($) {
	// Google Recaptcha v3
	grecaptcha.ready(function () {
		$('form.recaptcha-form').each(function () {
			var object = $(this);
			grecaptcha.execute('6LcEpa8UAAAAAOg3bMdzsTfpAMwC8pNWd3MV2LfX', { action: 'homepage' }).then(function (token) {
				$('<input>').attr({
					type: 'hidden',
					name: 'g-recaptcha-response',
					value: token
				}).appendTo(object)
			});
		});
	});

	responsive.init();

	$('.list-reviews').slick({
		appendArrows: false,
		dots: true,
		adaptiveHeight: true,
		autoplay: true,
		autoplaySpeed: 5000,
	});


	$('.section-heroes').slick({
		appendArrows: false,
		dots: true,
		adaptiveHeight: true,
		autoplay: true,
		autoplaySpeed: 5000,
	});


	ellipsed.ellipsis('.sub-list-intro p, .sub-list-intro', 2);

	// Ensure the function exists on the page before we call it.
	if (!!window['gdprSubscribeModal']) {
		// The form of which to attach the modal to.
		const formID = '.form-quick';
		// The url to link to for the privacy policy.
		const privacyPolicyURL = 'privacy-policy.html';
		// Color of the links in the modal
		var linkColour = '#911E32';
		// Color of the buttons in the modal.
		var buttonTextColour = '#FFF';
		// Call this per form.
		gdprSubscribeModal(formID, privacyPolicyURL, linkColour, buttonTextColour);
	}

	$('#blog-post .icon-share').on('click', (element) => {
		$('.sub-mobile-hero, .sub-copy > .sub-social').toggleClass('active');
	});

	$('#payment input[name="legal"]').on('click', (event) => {
		$("#payment .wrap-approval-required").slideToggle();
	});

	// Mobile nav button
	$('#nav-dropdown').on('click', () => {
		const nav = document.querySelector('nav');
		const dropdown = document.querySelector('#nav-dropdown');

		nav.classList.toggle('active');
		dropdown.classList.toggle('active');

		if (nav.classList.contains('active')) {
			document.querySelector('body').style.overflow = 'hidden';
			document.querySelector('body').style.position = 'relative';
		} else {
			document.querySelector('body').style.overflow = 'inherit';
			document.querySelector('body').style.position = 'inherit';
		}
	});

	$('header-main .list-nav a').on('click', () => {
		const nav = document.querySelector('nav');
		const dropdown = document.querySelector('#nav-dropdown');

		nav.classList.toggle('active');
		dropdown.classList.toggle('active');

		if (nav.classList.contains('active')) {
			document.querySelector('body').style.overflow = 'hidden';
			document.querySelector('body').style.position = 'relative';
		} else {
			document.querySelector('body').style.overflow = 'inherit';
			document.querySelector('body').style.position = 'inherit';
		}
	});

	document.querySelectorAll('[data-like]').forEach(element => {
		const data = JSON.parse(element.getAttribute('data-like'));

		element.addEventListener('click', (event) => {
			event.preventDefault();

			const url = `process/like/${data.type}/${data.id}`;

			$.ajax({
				url: url,
				method: 'POST'
			}).done((data, status) => {
				window.location.reload();
			});
		});
	});


	ajaxifyForm('#contact .section-form form', '/process/contact/', showSwalSending,
		(selector, data) => {
			$('.sub-errors').empty();

			const successMessage = $(`<div class="thanks container">
				<header>
					<h3>Thank you for submitting our contact form. We'll get back to you ASAP</h3>
				</header>
			</div>`);

			$(selector).animate({ opacity: 0 }, 1000, () => {
				$(selector).replaceWith(successMessage);
			});
		}, (selector, data) => {
			const errorNames = Object.keys(data.errors);

			$('.sub-errors').empty();

			errorNames.forEach(errorName => {
				$(`<p>${data.errors[errorName].message}</p>`).appendTo($('.sub-errors'));
			});
		}, Swal.close);

	ajaxifyForm('#quick-quote .wrap-quickquote form', '/process/quick-quote/', showSwalSending,
		(selector, data) => {
			$('.sub-errors').empty();

			const successMessage = $(`<form><div class="thanks container" style="text-align: center">
				<header>
					<h3>Thank you for submitting our contact form. We'll get back to you ASAP</h3>
				</header>
			</div></form>`);

			// _gaq.push(['_trackEvent', 'Quick Quote', 'Submitted']);

			$(selector).animate({ opacity: 0 }, 1000, () => {
				$(selector).replaceWith(successMessage);
			});
		}, (selector, data) => {
			const errorNames = Object.keys(data.errors);

			$('.sub-errors').empty();

			// if honeypot field is filled in by spambot, do not submit
			if ($('input#Form_Email').val().length != 0) {
				return false;
			}

			const container = $('<div></div>');

			errorNames.forEach(errorName => {
				const errorMessage = typeof (data.errors[errorName]) === 'object' ? data.errors[errorName].message : data.errors[errorName];
				$(`<p>${errorMessage}</p>`).appendTo(container);
			});

			Swal.fire({
				type: 'error',
				title: 'Some errors with your request',
				html: container.html(),
				backdrop: 'rgba(0, 0, 0, 0.7)'
			});
		}, (selector, data) => data.success && Swal.close());

	ajaxifyForm('.form-quick', '/process/subscribe/', showSwalSending,
		(selector, data) => {
			$('.sub-errors').empty();

			const successMessage = $(`<div class="thanks container" style="text-align: center">
				<header>
					<h3>Thank you for submitting our contact form. We'll get back to you ASAP</h3>
				</header>
			</div>`);


			_gaq.push(['_trackEvent', 'Subscribe Form', 'Submitted']);

			$(selector).animate({ opacity: 0 }, 1000, () => {
				$(selector).replaceWith(successMessage);
			});
		}, (selector, data) => {
			const errorNames = Object.keys(data.errors);

			$('.sub-errors').empty();

			const container = $('<div></div>');

			errorNames.forEach(errorName => {
				const errorMessage = typeof (data.errors[errorName]) === 'object' ? data.errors[errorName].message : data.errors[errorName];
				$(`<p>${errorMessage}</p>`).appendTo(container);
			});

			Swal.fire({
				type: 'error',
				title: 'Some errors with your request',
				html: container.html(),
				backdrop: 'rgba(0, 0, 0, 0.7)'
			});
		}, (selector, data) => data.success && Swal.close());


	ajaxifyForm('#faqs .wrap-faqs form', '/process/faq/', showSwalSending,
		(selector, data) => {
			$('.sub-errors').empty();

			const successMessage = $(`<form><div class="thanks container" style="text-align: center; display: flex; height: 300px; align-items: center; opacity: 0;">
				<header>
					<h3>Thank you for submitting our FAQ form. We'll get back to you ASAP</h3>
				</header>
			</div></form>`);

			_gaq.push(['_trackEvent', 'FAQ Form', 'Submitted']);

			$(selector).animate({ opacity: 0 }, 500, () => {
				$(selector).replaceWith(successMessage);
				$('.thanks').animate({ opacity: 1 }, 500);
			});
		}, (selector, data) => {
			const errorNames = Object.keys(data.errors);

			$('.sub-errors').empty();

			const container = $('<div></div>');

			errorNames.forEach(errorName => {
				const errorMessage = typeof (data.errors[errorName]) === 'object' ? data.errors[errorName].message : data.errors[errorName];
				$(`<p>${errorMessage}</p>`).appendTo(container);
			});

			Swal.fire({
				type: 'error',
				title: 'Some errors with your request',
				html: container.html(),
				backdrop: 'rgba(0, 0, 0, 0.7)'
			});
		}, (selector, data) => data.success && Swal.close());

	ajaxifyForm('#feedback .wrap-feedback form', '/process/feedback/', showSwalSending,
		(selector, data) => {
			$('.sub-errors').empty();

			const successMessage = $(`<form><div class="thanks container" style="text-align: center; display: flex; height: 300px; align-items: center; opacity: 0;">
		<header>
			<h3>Thank you for submitting our Feedback form.</h3>
		</header>
	</div></form>`);

			_gaq.push(['_trackEvent', 'Feedback Form', 'Submitted']);

			$(selector).animate({ opacity: 0 }, 500, () => {
				$(selector).replaceWith(successMessage);
				$('.thanks').animate({ opacity: 1 }, 500);
			});
		}, (selector, data) => {
			const errorNames = Object.keys(data.errors);

			$('.sub-errors').empty();

			const container = $('<div></div>');

			errorNames.forEach(errorName => {
				const errorMessage = typeof (data.errors[errorName]) === 'object' ? data.errors[errorName].message : data.errors[errorName];
				$(`<p>${errorMessage}</p>`).appendTo(container);
			});

			Swal.fire({
				type: 'error',
				title: 'Some errors with your request',
				html: container.html(),
				backdrop: 'rgba(0, 0, 0, 0.7)'
			});
		}, (selector, data) => data.success && Swal.close());

	$('.list-team .readtoggle').each((i, e) => {
		const toggleButton = $(e);
		const textContainer = toggleButton.prev('div');
		const originalText = textContainer.text();

		textContainer.text(`${originalText.substr(0, 200)}...`);
		toggleButton.find('span').text('More');

		toggleButton.on('click', () => {
			const currentText = textContainer.text();

			if (currentText === originalText) {
				textContainer.text(`${originalText.substr(0, 200)}...`);
				toggleButton.find('span').text('More');
			} else {
				textContainer.text(originalText);
				toggleButton.find('span').text('Less');
			}
		});
	});

	$('#quote-asap').on('click', () => {
		const value = $('#quote-asap').prop('checked');

		if (value) {
			$('#date').prop('disabled', true)
			$('#time').prop('disabled', true)
		} else {
			$('#date').prop('disabled', false)
			$('#time').prop('disabled', false)
		}
	});


	// Only run on pages with a map on it
	if ($('#map').length) {

		//Google maps
		const center = {
			lat: 51.675962,
			lng: -0.384934
		};

		const map = new MelonMap(document.getElementById('map'), { center: center, zoom: 9 });

		fetch(`process/map-pins`, {
			method: "get"
		}).then((d) => {
			return d.json()
		}).then(jsonData => {
			const location = jsonData;

			location.forEach((college) => {
				const title = college[0];

				const location = {
					lat: parseFloat(college[1]),
					lng: parseFloat(college[2])
				}

				const marker = map.addMarker(location, title);

				marker.addListener('click', function (marker) {
					const titleSpan = $('.section-details .sub-details div span:nth-child(1)');

					titleSpan.text(title)
				});

			});

			// map.centerMap();

		});
	}

	if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {

		$('aside h2, aside h2 + svg').on('click', () => {
			$('h2 + svg').toggleClass('mod-invert');

			$('aside nav').slideToggle(400);
		})
	}

});

function ajaxifyForm(selector, url, submit, success, fail, finish) {
	$(selector).on('submit', (e) => {
		// Stop the form from submitting normally.
		e.preventDefault();

		// Call the submit event.
		submit();

		// Send post request with data in it.
		$.ajax({
			url: url,
			method: 'POST',
			data: $(selector).serialize()
		}).done((data, status) => {
			if (status !== 'success') {
				return;
			}

			data = JSON.parse(data);

			const isSuccessful = data.success;

			if (isSuccessful) {
				success(selector, data);
			} else {
				fail(selector, data);
			}

			finish(selector, data);
		});
	});
}

function showSwalSending() {
	// console.log('does nothing anymore');
}